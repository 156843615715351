import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				description
				author
			}
		}
	}
`;

function SEO({ description, lang, meta, keywords, title, className }) {

	return (
		<StaticQuery
			query={detailsQuery}
			render={data => {
				const metaDescription = description || data.site.siteMetadata.description;
				const titleText = title ? `${title} | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title;

        		return (
          			<Helmet
						bodyAttributes={{ class: className }}
            			htmlAttributes={{ lang }}
            			title={titleText}
            			titleTemplate={`%s`}
            			meta={[{
							name: 'google-site-verification',
							content: 'PAsw7MS5yQHmKF5uvcOIbLWWGnXukN-Ap1aTZznO6HM'
						},
						{
                			name: `description`,
                			content: metaDescription,
              			},
              			{
                			property: `og:title`,
                			content: title,
              			},
              			{
                			property: `og:description`,
                			content: metaDescription,
              			},
              			{
                			property: `og:type`,
                			content: `website`,
              			},
              			{
                			name: `twitter:card`,
                			content: `summary`,
              			},
              			{
                			name: `twitter:creator`,
                			content: data.site.siteMetadata.author,
              			},
              			{
                			name: `twitter:title`,
                			content: title,
              			},
              			{
                			name: `twitter:description`,
                			content: metaDescription,
              			}]
              			.concat(
                			keywords.length > 0
                  			? {
                      			name: `keywords`,
                      			content: keywords.join(`, `),
                    		}
                  			: []
              			)
              			.concat(meta)}
          			/>
        		)
      		}}
    	/>
  	)
}

SEO.defaultProps = {
	lang: 'fr',
	meta: [],
	keywords: [],
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
	class: PropTypes.string,
}

export default SEO;
